<template>
    <div>
        <div
            class="mask"
            v-show="isShow"
            @click="closePreMassageLightbox"
        ></div>
        <transition name="slide">
            <div class="pre-massage-lightbox" v-show="isShow">
                <template v-if="isCheckoutDataEmpty">
                    <div class="tab-menu-2">
                        <div class="tab-menu2-wrap">
                            <div class="news-wrap">
                                <div class="news-header">
                                    <div class="news-title">
                                        <img
                                            src="@/assets/tgd/lightbox/small-massage.svg"
                                            alt=""
                                        />
                                        <span>前次加強部位 - {{ checkoutData.data.answer_list.customer_name }}</span>
                                    </div>
                                </div>
                                <div class="news-body">
                                    <div class="row no-gutters title">
                                        <div class="col-4"></div>
                                        <div
                                            class="col-4 text-gold"
                                            style="
                                                position: relative;
                                                left: -35px;
                                            "
                                        >
                                            正面
                                        </div>
                                        <div class="col-4 text-gold">背面</div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-6 news-body-left">
                                            <div class="check-area container">
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                >
                                                    <div class="col-6"></div>
                                                    <div class="col-3">輕</div>
                                                    <div class="col-3">重</div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                        pt-2
                                                    "
                                                >
                                                    <div class="col-6">
                                                        頭部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-head_1"
                                                            :checked="
                                                                orderLocation.data[1].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-head_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-head_2"
                                                            :checked="
                                                                orderLocation.data[1].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-head_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 85px"
                                                >
                                                    <div class="col-6">
                                                        胸部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-chest_1"
                                                            :checked="
                                                                orderLocation.data[2].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-chest_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-chest_2"
                                                            :checked="
                                                                orderLocation.data[2].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-chest_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 75px"
                                                >
                                                    <div class="col-6">
                                                        腹部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-belly_1"
                                                            :checked="
                                                                orderLocation.data[3].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-belly_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-belly_2"
                                                            :checked="
                                                                orderLocation.data[3].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-belly_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 74px"
                                                >
                                                    <div class="col-6">
                                                        正大腿
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-thigh_1"
                                                            :checked="
                                                                orderLocation.data[4].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-thigh_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-thigh_2"
                                                            :checked="
                                                                orderLocation.data[4].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-thigh_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="body-frant">
                                                <img
                                                    src="@/assets/tgd/lightbox/body-frant.svg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6 news-body-right">
                                            <div class="body-back">
                                                <img
                                                    src="@/assets/tgd/lightbox/body-back.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="check-area container">
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                >
                                                    <div class="col-6"></div>
                                                    <div class="col-3">輕</div>
                                                    <div class="col-3">重</div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 59px"
                                                >
                                                    <div class="col-6">
                                                        脖子
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-neck_1"
                                                            :checked="
                                                                orderLocation.data[7].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-neck_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-neck_2"
                                                            :checked="
                                                                orderLocation.data[7].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-neck_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 21px"
                                                >
                                                    <div class="col-6">
                                                        肩膀
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-shoulder_1"
                                                            :checked="
                                                                orderLocation.data[8].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-shoulder_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-shoulder_2"
                                                            :checked="
                                                                orderLocation.data[8].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-shoulder_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 20px"
                                                >
                                                    <div class="col-6">
                                                        上背部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-upper_back_1"
                                                            :checked="
                                                                orderLocation.data[9].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-upper_back_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-upper_back_2"
                                                            :checked="
                                                                orderLocation.data[9].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-upper_back_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 17px"
                                                >
                                                    <div class="col-6">
                                                        中背部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-mid_back_1"
                                                            :checked="
                                                                orderLocation.data[10].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-mid_back_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-mid_back_2"
                                                            :checked="
                                                                orderLocation.data[10].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-mid_back_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 17px"
                                                >
                                                    <div class="col-6">
                                                        腰部
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-waist_1"
                                                            :checked="
                                                                orderLocation.data[11].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-waist_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-waist_2"
                                                            :checked="
                                                                orderLocation.data[11].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-waist_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 73px"
                                                >
                                                    <div class="col-6">
                                                        後大腿
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-hind_thigh_1"
                                                            :checked="
                                                                orderLocation.data[12].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-hind_thigh_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-hind_thigh_2"
                                                            :checked="
                                                                orderLocation.data[12].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-hind_thigh_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        row
                                                        no-gutters
                                                        align-items-center
                                                    "
                                                    style="padding-top: 73px"
                                                >
                                                    <div class="col-6">
                                                        後小腿
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-hind_calf_1"
                                                            :checked="
                                                                orderLocation.data[13].indexOf(
                                                                    1
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-hind_calf_1"
                                                        ></label>
                                                    </div>
                                                    <div class="col-3">
                                                        <input
                                                            type="checkbox"
                                                            id="pre-hind_calf_2"
                                                            :checked="
                                                                orderLocation.data[13].indexOf(
                                                                    2
                                                                ) >= 0
                                                            "
                                                            disabled
                                                        />
                                                        <label
                                                            for="pre-hind_calf_2"
                                                        ></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="center-check container p-0">
                                        <div
                                            class="
                                                row
                                                no-gutters
                                                align-items-center
                                            "
                                        >
                                            <div class="col-6"></div>
                                            <div class="col-3">輕</div>
                                            <div class="col-3">重</div>
                                        </div>
                                        <div
                                            class="
                                                row
                                                no-gutters
                                                align-items-center
                                                pt-2
                                            "
                                        >
                                            <div class="col-6">上手臂</div>
                                            <div class="col-3">
                                                <input
                                                    type="checkbox"
                                                    id="pre-upper_arm_1"
                                                    :checked="
                                                        orderLocation.data[5].indexOf(
                                                            1
                                                        ) >= 0
                                                    "
                                                    disabled
                                                />
                                                <label
                                                    for="pre-upper_arm_1"
                                                ></label>
                                            </div>
                                            <div class="col-3">
                                                <input
                                                    type="checkbox"
                                                    id="pre-upper_arm_2"
                                                    :checked="
                                                        orderLocation.data[7].indexOf(
                                                            1
                                                        ) >= 0
                                                    "
                                                    disabled
                                                />
                                                <label
                                                    for="pre-upper_arm_2"
                                                ></label>
                                            </div>
                                        </div>
                                        <div
                                            class="
                                                row
                                                no-gutters
                                                align-items-center
                                            "
                                            style="padding-top: 48px"
                                        >
                                            <div class="col-6">下手臂</div>
                                            <div class="col-3">
                                                <input
                                                    type="checkbox"
                                                    id="pre-lower_arm_1"
                                                    :checked="
                                                        orderLocation.data[6].indexOf(
                                                            1
                                                        ) >= 0
                                                    "
                                                    disabled
                                                />
                                                <label
                                                    for="pre-lower_arm_1"
                                                ></label>
                                            </div>
                                            <div class="col-3">
                                                <input
                                                    type="checkbox"
                                                    id="pre-lower_arm_2"
                                                    :checked="
                                                        orderLocation.data[6].indexOf(
                                                            2
                                                        ) >= 0
                                                    "
                                                    disabled
                                                />
                                                <label
                                                    for="pre-lower_arm_2"
                                                ></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-wrap">
                                    <button
                                        class="btn"
                                        @click="closePreMassageLightbox"
                                    >
                                        確認
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

export default {
    name: "PreMassageLightbox",
    props: {
        orderId: {
            type: [Number, String],
            required: true,
        },
    },
    setup(props) {
        const orderId = computed(() => props.orderId);

        const { state } = useStore();
        const storeId = state.auth.store_id;
        const isShow = computed(() => state.global.isPreMassageLightboxShow);
        const { closePreMassageLightbox } = useHandleLightboxOpen();

        let checkoutData = reactive({ data: {} });
        let orderLocation = reactive({ data: {} });
        //取得結帳資料
        const getCheckoutData = async () => {
            try {
                const res = await basePost("/api_order/order_details", {
                    store_id: storeId,
                    order_id: orderId.value,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    checkoutData.data = data.res_data;
                    orderLocation.data =
                        checkoutData.data.answer_list.order_location;
                    return data.res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };
        const isCheckoutDataEmpty = computed(
            () => Object.keys(checkoutData.data).length
        );
        watch([isShow, orderId], (arr) => {
            const showStatus = arr[0];
            const id = arr[1];
            
            if (showStatus && id) {
                getCheckoutData();
            }
        });

        return {
            isShow,
            closePreMassageLightbox,
            isCheckoutDataEmpty,
            orderLocation,
            checkoutData
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 640px;
$tab-width: 120px;
$tab-menu-width: 895px;
.pre-massage-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: 930px;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    @include pad {
        width: 100%;
    }
    .tab-menu-2 {
        width: 100%;
        min-height: 100%;
        background: #f8f8f8;
        position: absolute;
        right: 0;
        top: 0;
        transition: ease-in 0.4s;
        box-shadow: 0px 3px 6px #00000029;
        .tab-menu2-wrap {
            overflow: overlay;
            height: 100vh;
            position: relative;

            &::-webkit-scrollbar-track {
                background-color: #d6d6d6;
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #707070;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 9px;
            }
            .news-wrap {
                padding-top: 60px;
                padding-left: 45px;
                padding-right: 85px;
                padding-bottom: 50px;
                @include pad {
                    width: 930px;
                    overflow: overlay;
                }
                .news-header {
                    display: flex;
                    align-items: center;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #a4641a;
                    .news-title {
                        font-size: 20px;
                        color: #a4641a;
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        span {
                            line-height: 22px;
                            padding-left: 10px;
                        }
                    }
                }
                .news-body {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    position: relative;
                    .title {
                        padding-top: 45px;
                        padding-bottom: 50px;
                        .text-gold {
                            color: #a4641a;
                        }
                    }
                    .col-6 {
                        display: flex;
                        .check-area {
                            width: 100%;
                            position: relative;
                            top: -10px;
                            padding: 0;
                            .no-gutters {
                                .col-3 {
                                    display: flex;
                                    justify-content: flex-end;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                    .body-frant {
                        margin-right: 30px;
                    }
                    .body-back {
                        margin-left: 30px;
                    }
                    .center-check {
                        width: 120px;
                        position: absolute;
                        top: 250px;
                        left: 39%;
                        .no-gutters {
                            .col-3 {
                                display: flex;
                                justify-content: flex-end;
                                padding-right: 10px;
                            }
                        }
                    }
                    input {
                        display: none;
                    }
                    label {
                        background: no-repeat center/contain
                            url(~@/assets/tgd/lightbox/checkbox-none.svg);
                        padding: 10px;
                        cursor: default;
                    }
                    input:checked + label {
                        background: no-repeat center/contain
                            url(~@/assets/tgd/lightbox/checkbox-block.svg);
                    }
                }
                .btn-wrap {
                    position: absolute;
                    bottom: 50px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    @include phone {
                        position: relative;
                        bottom: unset;
                        left: unset;
                        transform: unset;
                        margin-top: 20px;
                    }
                    .btn {
                        height: 70px;
                        width: 217px;
                        border-radius: 11px;
                        padding: 15px;
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #4a4a4a 0% 0% no-repeat padding-box;
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                        letter-spacing: 0px;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                        @include phone {
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
}
</style>
