<template>
    <div class="index no-print-sec">
        <CheckoutLightbox
            :orderId="orderId"
            :submitType="submitType"
            openBy="index"
        />
        <PreMassageLightbox :orderId="orderId" />
        <DivideClassLightbox
            v-model:isShow="divideClass.isShow"
            :orderId="divideClass.orderId"
        />
        <EndServiceLightbox
            v-model:isShow="endService.isShow"
            :orderId="endService.orderId"
        />
        <div class="top">
            <Logo />
            <TopTab />
        </div>
        <div class="index-container">
            <div class="title d-none d-xxl-block">進行中訂單</div>

            <transition appear name="fade" v-if="requestState.isReady">
                <div class="row">
                    <div class="col-xl-9 order-1">
                        <div class="order-list-wrap">
                            <div class="order-title">進行中訂單</div>
                            <div class="row order-list-header no-gutters">
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -10px"
                                >
                                    服務時間
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -30px"
                                >
                                    客人
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -40px"
                                ></div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -80px"
                                >
                                    芳療師
                                </div>
                                <div
                                    class="col-1 position-relative"
                                    style="left: -80px"
                                >
                                    服務項目
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -10px"
                                >
                                    房間
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <div
                                class="row order-list no-gutters"
                                v-for="list in orderList.data"
                                :key="list.id"
                                :class="{ active: list.ten_color }"
                                @click="
                                    passId2CheckoutLightbox(
                                        list.id,
                                        [1, 9].includes(list.payment_status) ? 2 : 0
                                    )
                                "
                            >
                                <div
                                    class="active-icon"
                                    v-show="list.ten_color"
                                >
                                    <img
                                        src="@/assets/tgd/index/doing-white.svg"
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -10px"
                                >
                                    {{ list.start_time }}-{{ list.end_time }}
                                </div>
                                <div
                                    class="
                                        col-1
                                        d-flex
                                        align-items-center
                                        justify-content-center
                                        position-relative
                                    "
                                    style="left: -30px"
                                >
                                    <span>{{ list.customer_name }}</span>
                                </div>
                                <div
                                    class="col-1 position-relative"
                                    style="left: -40px"
                                >
                                    <div class="img-wrap">
                                        <div class="wrap">
                                            <a
                                                v-show="list.remark"
                                                href="javascript:;"
                                                data-bs-toggle="tooltip"
                                                :title="list.remark"
                                            >
                                                <img
                                                    src="@/assets/tgd/index/warning.svg"
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                        <div 
                                            v-show="list.order_location_ary"
                                            class="wrap"
                                        >
                                            <a
                                                href="javascript:;"
                                                @click.stop="
                                                    passId2PreMassageLightbox(
                                                        list.id
                                                    )
                                                "
                                                
                                            >
                                                <img
                                                    src="@/assets/tgd/index/increase-part.svg"
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                        <div 
                                            v-show="list.service_type == 1"
                                            class="wrap"
                                        >
                                            <a href="javascript:;" class="btn-icon">
                                                老
                                            </a>
                                        </div>
                                        <div 
                                            v-show="list.is_use_ticket == 1"
                                            class="wrap"
                                        >
                                            <a href="javascript:;" class="btn-icon yellow">
                                                劵
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -80px; pointer-events: none"
                                >
                                    {{ list.member_name }}
                                    <template v-if="list.divide_class_member_id > 0">
                                        <br>{{ list.divide_class_member_name }}
                                    </template>
                                </div>
                                <div
                                    class="col-1 position-relative"
                                    style="left: -80px"
                                >
                                    {{ list.service_name }}
                                </div>
                                <div
                                    class="
                                        col-1
                                        position-relative
                                        d-flex
                                        justify-content-center
                                    "
                                    style="left: -10px"
                                >
                                    {{ list.room_name }}
                                </div>

                                <div
                                    class="col-4 d-flex justify-content-start"
                                    v-if="list.payment_status === 1"
                                >
                                    <p
                                        class="checked"
                                    >
                                        已結帳
                                    </p>
                                    <button
                                        type="button"
                                        class="btn btn-edit me-2"
                                        @click.stop="cancelAlert(list.id)"
                                    >
                                        取消訂單
                                    </button>
                                    <p
                                        v-if="list.divide_class_member_id > 0"
                                        class="checked"
                                    >
                                        已分班
                                    </p>
                                    <button
                                        v-else-if="list.order_status != 9"
                                        type="button"
                                        class="btn btn-edit me-2"
                                        @click.stop="
                                            openDivideClass(list.id)
                                        "
                                    >
                                        分班
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-edit me-2"
                                        @click.stop="
                                            passId2CheckoutLightbox(list.id, 3)
                                        "
                                    >
                                        續訂
                                    </button>
                                    <p
                                        v-if="list.order_status == 9"
                                        class="checked me-0"
                                    >
                                        已結束<br>
                                        服務
                                    </p>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-edit me-2"
                                        @click.stop="
                                            openEndService(list.id)
                                        "
                                    >
                                        結束服務
                                    </button>
                                </div>
                                <div
                                    class="col-4 d-flex justify-content-start"
                                    v-else-if="list.payment_status === 9"
                                >
                                    <p
                                        class="checked"
                                    >
                                        {{ list.payment_statusStr }}
                                    </p>
                                </div>
                                <div
                                    class="col-4 d-flex justify-content-start"
                                    v-else
                                >
                                    <button
                                        type="button"
                                        class="btn btn-checkout"
                                        @click.stop="
                                            passId2CheckoutLightbox(list.id, 1)
                                        "
                                    >
                                        結帳
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-edit me-3"
                                        @click.stop="cancelAlert(list.id)"
                                    >
                                        取消訂單
                                    </button>
                                    <p
                                        v-if="list.divide_class_member_id > 0"
                                        class="checked"
                                    >
                                        已分班
                                    </p>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-edit me-3"
                                        @click.stop="
                                            openDivideClass(list.id)
                                        "
                                    >
                                        分班
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 order-3 order-xl-2">
                        <div class="news-wrap"
                            v-show="requestState.announcementList"
                        >
                            <div class="news-header">
                                <div class="news-title">
                                    <img
                                        src="@/assets/tgd/index/notice.svg"
                                        alt=""
                                    />
                                    <span>公告</span>
                                </div>
                                <div class="more">
                                    <router-link to="/post">more</router-link>
                                </div>
                            </div>
                            <div class="news-body">
                                <template
                                    v-for="(
                                        announcement, index
                                    ) in announcementList.data"
                                    :key="index"
                                >
                                    <p class="post-title">
                                        {{ announcement.start_date }}-{{
                                            announcement.end_date
                                        }}{{ announcement.title }}
                                    </p>
                                    <div
                                        class="post-text"
                                        v-html="announcement.content"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="staff-show-wrap">
                            <div class="staff-num row">
                                <div class="col-4">
                                    <p>
                                        <span class="should">{{
                                            memberWorkList.data.should_people
                                        }}</span>
                                        人
                                    </p>
                                    <p>應到人數</p>
                                </div>
                                <div class="col-4">
                                    <p>
                                        <span class="actually">{{
                                            memberWorkList.data.real_people
                                        }}</span>
                                        人
                                    </p>
                                    <p>實到人數</p>
                                </div>
                                <div class="col-4">
                                    <p>
                                        <span class="num">{{
                                            memberWorkList.data.sign_people
                                        }}</span>
                                        人
                                    </p>
                                    <p>簽到人數</p>
                                </div>
                            </div>
                            <div class="staff-header">
                                <div class="staff-title">
                                    <img
                                        src="@/assets/tgd/index/lock.svg"
                                        alt=""
                                    />
                                    <span>當班芳療師</span>
                                    <span class="hint">(依簽到順序)</span>
                                </div>
                            </div>
                            <div class="staff-body">
                                <div
                                    class="row"
                                    v-for="item in memberWorkList.data.list"
                                    :key="item.id"
                                >
                                    <div class="col-2 pe-0">
                                        {{ item.member_name }}
                                    </div>
                                    <div class="col-6 text-center">
                                        {{ item.scheduled_start_time }}-{{
                                            item.scheduled_end_time
                                        }}
                                    </div>
                                    <div class="col-4 text-center">
                                        {{ item.lounge_name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row order-2 order-xl-3">
                        <div class="col-xl-9" v-if="pageInfo.totalPage > 1">
                            <BasePagination
                                :pageInfo="pageInfo"
                                @pageChange="updatePageData"
                            />
                        </div>
                        <div class="col-3"></div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, nextTick, watch, onMounted } from "vue";
import { useStore } from "vuex";
import useTooltip from "@/hooks/common/useTooltip";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { basePost } from "@/js/services/baseService";
import Swal from "sweetalert2";

import Logo from "@/components/includes/Logo.vue";
import TopTab from "@/components/includes/TopTab.vue";
import CheckoutLightbox from "@/components/lightbox/CheckoutLightbox.vue";
import PreMassageLightbox from "@/components/lightbox/PreMassageLightbox.vue";
import DivideClassLightbox from "@/components/lightbox/DivideClassLightbox.vue";
import EndServiceLightbox from "@/components/lightbox/EndServiceLightbox.vue";
import NoData from "@/components/includes/NoData.vue";

export default {
    name: "Index",
    components: {
        Logo,
        TopTab,
        CheckoutLightbox,
        PreMassageLightbox,
        DivideClassLightbox,
        EndServiceLightbox,
        NoData,
    },
    setup() {
        const { state, commit, dispatch } = useStore();
        const isShow = computed(() => state.global.isCheckoutLightboxShow);

        // 開燈箱
        const {
            openCheckoutLightbox,
            openPreMassageLightbox,
        } = useHandleLightboxOpen();

        // 頁籤設定
        const pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });

        // 訂單列表
        let orderList = reactive({ data: [] });
        // 訂單 ID
        const orderId = ref("");

        let submitType = ref("");

        let currentPage = ref(1);

        // 公告資訊
        let announcementList = reactive({ data: [] });

        // 當班芳療師資訊
        let memberWorkList = reactive({ data: [] });

        const requestState = reactive({
            orderList: false,
            memberWorkList: false,
            isReady: computed(() => {
                return requestState.orderList && requestState.memberWorkList
            }),
            announcementList: false
        })

        //取得訂單列表
        const getOrderList = async (num = 1) => {
            try {
                const res = await basePost("/api_order/order_list", {
                    page: num,
                    store_id: state.auth.store_id,
                });
                const { status, data } = res;
                requestState.orderList = true;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    orderList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
                nextTick(() => {
                    useTooltip();
                });
            } catch (err) {
                console.error(err);
            }
        };
        const updatePageData = (num) => {
            currentPage.value = num;
            getOrderList(num);
        };
        getOrderList();

        // 取得公告資訊
        const getPost = async () => {
            try {
                const res = await basePost("/api_common/announcement_list", {
                    store_id: state.auth.store_id,
                });
                const { status, data } = res;
                requestState.announcementList = true
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    announcementList.data = res_data.list;
                }
            } catch (err) {
                console.error(err);
            }
        };

        getPost();

        // 取得當班芳療師資訊
        const getMemberWorkStatus = async () => {
            try {
                const res = await basePost("/api_roster/member_work_status", {
                    store_id: state.auth.store_id,
                });
                const { status, data } = res;
                requestState.memberWorkList = true;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    memberWorkList.data = res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };

        getMemberWorkStatus();

        // 取得訂單列表 ID 開燈箱
        const passId2CheckoutLightbox = (order_id, type) => {
            orderId.value = order_id;
            submitType.value = type;
            openCheckoutLightbox();
        };

        // 取得訂單列表 ID 開前次加強燈箱
        const passId2PreMassageLightbox = (order_id) => {
            orderId.value = order_id;
            openPreMassageLightbox();
        };

        // 取消訂單
        const cancelOrder = async (orderId) => {
            try {
                const res = await basePost("/api_order/cancel_order_check", {
                    order_id: orderId,
                });
                const { status, data } = res;
                Swal.fire({
                    title: "取消成功!",
                    icon: "success",
                    confirmButtonText: "確定",
                }).then(() => {
                    getOrderList();
                });
            } catch (err) {
                console.error(err);
            }
        };

        // 取消提示
        const cancelAlert = (orderId) => {
            Swal.fire({
                title: "是否確認取消訂單?",
                showCancelButton: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
            }).then(function (result) {
                if (result.value) {
                    cancelOrder(orderId);
                }
            });
        };

        watch(isShow, () => {
            if (!isShow.value) {
                orderId.value = ''
                updatePageData(currentPage.value);
            }
        });

        const divideClass = reactive({
            isShow: false,
            orderId: 0
        })
        watch(
            ()=> divideClass.isShow,
            (isShow) => {
            if(!isShow) {
                divideClass.orderId = 0;
                updatePageData(currentPage.value);
            }
        })
        function openDivideClass(orderId) {
            divideClass.isShow = true;
            divideClass.orderId = orderId;
        }


        const endService = reactive({
            isShow: false,
            orderId: 0
        })
        watch(
            () => endService.isShow,
            (isShow) => {
            if(!isShow) {
                endService.orderId = 0;
                updatePageData(currentPage.value);
            }
        })
        function openEndService(orderId) {
            endService.isShow = true;
            endService.orderId = orderId;
        }

        return {
            updatePageData,
            openCheckoutLightbox,
            openPreMassageLightbox,
            orderList,
            orderId,
            submitType,
            passId2CheckoutLightbox,
            passId2PreMassageLightbox,
            pageInfo,
            announcementList,
            memberWorkList,
            cancelAlert,
            divideClass,
            openDivideClass,
            endService,
            openEndService,
            requestState
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.index {
    .index-container {
        padding-right: 0 !important;
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
        }

        .order-list-wrap,
        .news-wrap,
        .staff-show-wrap {
            margin-bottom: 15px;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            filter: contrast(100%);
            transition: all 0.3s;
            &:hover {
                filter: contrast(105%);
            }
        }
        .order-list-wrap {
            min-height: 837px;
            background: transparent
                linear-gradient(122deg, #ffffff 0%, #d9d9d9 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            padding: 70px 70px 45px;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            @media (max-width: 1400px) {
                padding: 60px 30px 30px;
            }
            @media (max-width: 1200px) {
                min-height: unset;
            }
            .order-title {
                color: #a4641a;
                font-size: 17px;
                font-weight: bold;
                position: relative;
                padding-left: 15px;
                display: none;
                z-index: 1;
                top: -40px;
                left: 0;
                &:before {
                    position: absolute;
                    content: "";
                    top: -2px;
                    bottom: -2px;
                    left: 0;
                    border: 3px solid #a4641a;
                }
                @media (max-width: 1400px) {
                    display: block;
                }
            }
            .order-list-header {
                position: relative;
                top: -20px;
            }
            .order-list {
                cursor: pointer;
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 11px;
                padding: 17px 0;
                align-items: center;
                margin-bottom: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(90%);
                }
                .img-wrap {
                    display: flex;
                    justify-content: center;
                    .wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 2px;
                        a {
                            display: block;
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                            box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
                            img {
                                filter: contrast(100%);
                                transition: all 0.3s;
                                &:hover {
                                    filter: contrast(160%);
                                }
                            }
                        }
                    }
                }
                .btn {
                    width: 80px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(140%);
                    }
                }
                .btn-checkout {
                    background: transparent
                        linear-gradient(
                            293deg,
                            #a4641a 0%,
                            #a4641a 64%,
                            #ebc496 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #ffffff85;
                    border-radius: 11px;
                    color: #fff;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    margin-right: 15px;
                }
                .btn-edit {
                    background: transparent
                        linear-gradient(
                            1deg,
                            #ececec 0%,
                            #ffffff 67%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    color: #a4641a;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                }
                .checked {
                    width: 80px;
                    height: 42px;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #a4641a;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    cursor: pointer;
                }
            }
            .active {
                background: #d67675 0% 0% no-repeat padding-box;
                color: #fff;
                position: relative;
                .active-icon {
                    position: absolute;
                    left: -17px;
                    background: #ffa5a5 0% 0% no-repeat padding-box;
                    border-radius: 50%;
                    width: 43px;
                    height: 43px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .news-wrap,
        .staff-show-wrap {
            background: transparent
                linear-gradient(148deg, #ffffff 0%, #e5e5e5 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
        }
        .news-wrap {
            .news-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                padding-bottom: 15px;
                padding-right: 35px;
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    border-bottom: 1px solid #a4641a;
                    right: 30px;
                    left: 20px;
                    bottom: 0;
                }
                .news-title {
                    font-size: 20px;
                    color: #a4641a;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    span {
                        line-height: 22px;
                        padding-left: 10px;
                    }
                }
                .more {
                    a {
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        color: #a4641a;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(150%);
                        }
                    }
                }
            }
            .news-body {
                height: 171px;
                overflow-y: auto;
                padding: 0px 15px;
                margin: 10px;
                .post-title {
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0.8px;
                    color: #b77f1a;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    line-height: 21px;
                }
                .post-text {
                    line-height: 23px;
                }
                &::-webkit-scrollbar-track {
                    background-color: #d6d6d6;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #707070;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 9px;
                }
            }
        }
        .staff-show-wrap {
            height: calc(100% - 270px);
            .staff-num {
                padding: 30px;
                padding-bottom: 20px;
                .col-4 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &:after {
                        position: absolute;
                        content: "";
                        top: 10px;
                        bottom: 10px;
                        right: 0;
                        border-right: 1px solid #ccc;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    p {
                        margin-bottom: 10px;
                        span {
                            font-size: 28px;
                            font: normal normal bold 28px/38px Open Sans;
                        }
                        .should {
                            color: #d5a240;
                        }
                        .actually {
                            color: #a4641a;
                        }
                        .num {
                            color: #d67675;
                        }
                    }
                }
            }
            .staff-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                padding-bottom: 15px;
                padding-right: 35px;
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    border-bottom: 1px solid #a4641a;
                    right: 30px;
                    left: 20px;
                    bottom: 0;
                }
                .staff-title {
                    font-size: 20px;
                    color: #a4641a;
                    display: flex;
                    align-items: flex-end;
                    font-weight: bold;
                    span {
                        line-height: 22px;
                        padding-left: 10px;
                    }
                    .hint {
                        font-size: 16px;
                    }
                }
            }
            .staff-body {
                overflow-y: auto;
                padding: 0px 15px;
                margin: 10px;
                padding-bottom: 15px;
                &::-webkit-scrollbar-track {
                    background-color: #d6d6d6;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #707070;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 9px;
                }
                @include phone {
                    min-height: 250px;
                }
                .row {
                    align-items: center;
                    padding-top: 10px;
                    font: normal normal normal 16px/22px Open Sans;
                }
            }
        }
    }

    .btn-icon {
        display: inline-block;
        @include size(28px);
        margin-bottom: 0;
        border-radius: 50px;
        text-align: center;
        line-height: 28px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #9F6464;
        color: #fff;
        &.yellow {
            background-color: #FFC90E;
            color: #000;
        }
    }
}
</style>
